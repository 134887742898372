.App {
  min-height: 100vh;
  background: linear-gradient(45deg, rgba(252,175,69,1) 0%, rgba(247,119,55,1) 10%, rgba(245,96,64,1) 20%, rgba(249,55,55,1) 30%, rgba(225,48,108,1) 40%, rgba(193,53,132,1) 50%, rgba(131,58,180,1) 60%, rgba(88,81,219,1) 70%, rgba(64,93,230,1) 80%, rgba(64,146,230,1) 90%, rgba(64,196,230,1) 100%);
  animation: example 15s infinite;
  background-size: 400% 400%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all .5s ease-in;
  position: relative;
  z-index: 9;
}

.App .AppError {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
  transition: opacity .2s;
  background: #d81b60;
}

.App .AppError.Active {
  opacity: 1;
}