@keyframes timer {
    0%{transform: scale(1)}
    50%{transform: scale(1.2)}
    100%{transform: scale(1)}
  }

.timer {
    height: 6rem;
    width: 6rem;
    background-color: #fff;
    font-size: 2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
    border: none;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}