* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

@keyframes example {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}

body {
  font-family: 'Vazir';
  box-sizing: border-box;
}

html {
  direction: rtl;
}